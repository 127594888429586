<template>
  <div>
    <Modal
      v-if="displayModal"
      label="Survey Results"
      show_cancel
      cancel_btn_text="Close"
      no_submit
      blocking
      large
      @close="displayModal = false"
    >
      <template v-slot:content>
        <div class="card">
          <div v-if="!isPopupLoading">
            <template v-if="surveyAnswers.fields && surveyAnswers.responses">
              <div
                class="pt-2"
                v-for="(field, key) in surveyAnswers.fields"
                :key="key"
              >
                <div>
                  {{ field.name }}
                </div>

                <div class="thirdhead-text">
                  {{ findAnswer(field) }}
                </div>
              </div>
            </template>
          </div>

          <div v-else>
            <LoadingSpinner />
          </div>
        </div>
      </template>
    </Modal>
    <div class="header-text pb-3" data-cy="header-admin-surveys">
      Student Surveys
    </div>

    <div class="flex">
      <div class="flex-auto">
        <div class="mb-2">Term</div>
        <select class="mb-2 w-1/2 rounded border-dark-gray py-1" v-model="term">
          <option
            class="h-8"
            v-for="(term, key) in terms"
            :key="key"
            :value="term"
          >
            {{ term.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="box-style mb-6">
      <div v-if="isLoading">
        <LoadingSpinner />
      </div>
      <div v-else>
        <dynamic-table
          :records="this.records"
          :fields="tableConfig"
          :pagination-page="paginationPage"
          :pagination-total-items="paginationTotalItems"
          @pageChanged="nextPage($event)"
          no-data-msg=""
          hover
          striped
          pagination
        >
          <template #cell(name)="{ item }">
            {{ item.student_at_level.student.firstname }}
            {{ item.student_at_level.student.lastname }}
          </template>
          <template #cell(mentor)="{ item }">
            {{ item.mentor.firstname }} {{ item.mentor.lastname }}
          </template>
          <template #cell(survey_date)="{ item }">
            {{ this.FormatDate(item.student_survey_date) }}
          </template>
          <template #cell(response)="{ item }">
            <div v-if="surveyExists(item)">
              <Button
                class="py-0 px-0 my-1"
                text="View Survey"
                @click="viewSurveyResults(item)"
              />
            </div>
            <div v-else>No Survey Available</div>
          </template>
        </dynamic-table>
      </div>
    </div>
  </div>
</template>

<script>
import Button from "@/components/reusable-components/base-level-components/Button";
import LoadingSpinner from "@/components/reusable-components/LoadingSpinner";
import DynamicTable from "@/components/displays/admin-displays/DynamicTable";
import Modal from "@/components/modal/Modal";
import { get } from "@/composables/httpUtil";
import { mapActions, mapGetters } from "vuex";
import { FormatDate } from "@/composables/date_utils";

export default {
  name: "AdminSurveys",
  components: {
    LoadingSpinner,
    DynamicTable,
    Button,
    Modal,
  },
  data() {
    return {
      isLoading: true,
      isPopupLoading: false,
      displayModal: false,
      surveyAnswers: null,
      records: [],
      term: null,
      terms: [],
      paginationPage: 1,
      itemsPerPage: 25,
      paginationTotalItems: 0,
      tableConfig: [
        {
          display: "Student Name",
          name: "name",
          sortable: false,
          class: "",
        },
        {
          display: "Mentor Name",
          name: "mentor",
          sortable: false,
          class: "",
        },
        {
          display: "Survey Date",
          name: "survey_date",
          sortable: false,
          class: "",
        },
        {
          display: "Survey Response",
          name: "response",
          sortable: false,
          class: "",
        },
      ],
    };
  },
  mounted() {
    this.fetchLookupResource().then(
      get("admin/terms")
        .then((terms) => {
          this.terms = terms.data;
          this.term = this.getCurrentTerm;
          // get students
        })
        .finally(() => {
          this.$watch(
            (vm) => vm.term,
            () => {
              this.getData();
            },
            { immediate: true }
          );
        })
    );
  },
  methods: {
    get,
    FormatDate,
    surveyExists(item) {
      return item.student_at_level.student.surveys.find((s) => {
        return (
          s.term_id === this.term.term_id &&
          s.mentor_id === item.mentor.mentor_id
        );
      });
    },
    viewSurveyResults(item) {
      let survey = this.surveyExists(item);
      this.displayModal = true;
      this.isPopupLoading = true;
      this.get(`admin/surveys/response/${survey.id}`).then(
        (results) => {
          this.surveyAnswers = results;
          this.isPopupLoading = false;
        },
        () => {
          this.isPopupLoading = false;
          this.displayModal = false;
        }
      );
    },
    findAnswer(field) {
      let answer = "No response found";
      const response = this.surveyAnswers.responses.find(
        (response) => response.field_id === field.field_id
      );
      if (response !== undefined) {
        if (response.value && response !== "") {
          answer = response.value;
        } else {
          answer = "No response given";
        }
      }
      return answer;
    },
    getData() {
      this.isLoading = true;
      let url = `admin/students/survey?&termId=${this.term.term_id}&page=${this.paginationPage}&limit=${this.itemsPerPage}`;
      get(url)
        .then((results) => {
          this.records = results.data;
        })
        .catch((error) => {
          console.log("error in catch statement", error);
          this.records = [];
        })
        .finally(() => (this.isLoading = false));
    },
    nextPage(pageChanged) {
      this.paginationPage = pageChanged.newPage;
      this.fetchMentors();
    },
    ...mapActions(["fetchLookupResource"]),
  },
  computed: {
    ...mapGetters(["getCurrentTerm"]),
  },
};
</script>

<style scoped></style>
